import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// A few different variant options, I feel as though only fade + slide look good enough
const fadeVariants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
};

const horizontalSlideVariants = {
    hidden: { opacity: 0, x: -100 },
    enter: { opacity: 1, x: 0, transition: { duration: 0.4 } },
    exit: { opacity: 0, x: 100, transition: { duration: 0.4 } },
};

const verticalSlideVariants = {
    hidden: { opacity: 0, y: -100 }, 
    enter: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 100, transition: { duration: 0.5 } },
};

const zoomVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    enter: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
};

const rotateVariants = {
    hidden: { opacity: 0, rotate: -180 },
    enter: { opacity: 1, rotate: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, rotate: 180, transition: { duration: 0.5 } },
};

const complexVariants = {
    hidden: { opacity: 0, x: -100, scale: 0.8 },
    enter: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: 100, scale: 0.8, transition: { duration: 0.5 } },
};

const variants = verticalSlideVariants; 

const PageAnimation = ({ children }) => {
    useEffect(() => { }, []);

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.main
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={variants}
                transition={{ type: 'linear' }}
            >
                {children}
            </motion.main>
        </AnimatePresence>
    );
};

export default PageAnimation;
